/* External dependencies */
import { Col, Container, Row, Text } from 'atomize';
import { graphql, useStaticQuery } from 'gatsby';
import { navigate } from 'gatsby-plugin-react-intl';
import { Helmet } from 'react-helmet';
import React, { useState } from 'react';
import { useSelector } from 'react-redux';

/* Local dependencies */
import Layout from '../../../components/layout/';
import BackNav from '../../../components/nav/BackNav';
import DangerPopup from '../../../components/popup/DangerPopup';
import UserDetailsForm from '../../../components/user-details/UserDetailsForm';
import formatMessage from '../../../intl/formatMessage';
import { RootState } from '../../../redux/store';

export default function UserDetails() {
  const title = formatMessage('users');
  const {
    site: {
      siteMetadata: { titleTemplate },
    },
  } = useStaticQuery(query);

  const [showExitconfirmation, setConfirmationState] = useState(false);

  const { user, isUserFormChanged } = useSelector(
    (state: RootState) => state.user,
  );

  const isExistFilledUserDetail =
    user && Object.entries(user).filter(([_, v]) => !!v).length >= 1;

  function onClickBackNav() {
    isExistFilledUserDetail && isUserFormChanged
      ? setConfirmationState(true)
      : navigate('/users');
  }

  return (
    <Layout>
      <Helmet>
        <title>{titleTemplate.replace('%s', title)}</title>
      </Helmet>
      <Container>
        <Row>
          <Col size={{ xs: '12', lg: '6' }} maxW="600px" w="100%">
            <BackNav
              label={formatMessage('updateUser')}
              onClick={onClickBackNav}
            />
            <UserDetailsForm />
          </Col>
        </Row>
        {showExitconfirmation && (
          <DangerPopup
            cancelText={formatMessage('cancel')}
            onClose={() => setConfirmationState(false)}
            onSubmit={() => navigate('/users')}
            submitText={formatMessage('exit')}
            title={formatMessage('updateUserExitTitle')}
          >
            <Text>{formatMessage('newUserExitDescription')} </Text>
          </DangerPopup>
        )}
      </Container>
    </Layout>
  );
}

const query = graphql`
  query {
    site {
      siteMetadata {
        titleTemplate
      }
    }
  }
`;
