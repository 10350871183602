/* External dependencies */
import { Col, Row, Text, Div } from 'atomize';
import { navigate } from 'gatsby-plugin-react-intl';
import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';

/* Local dependencies */
import formatMessage from '../../intl/formatMessage';
import { RootState } from '../../redux/store';
import PrimaryButton from '../buttons/PrimaryButton';
import { isBrowser } from '../common/helpers';
import roles from '../common/roles';
import DropdownPicker from '../custom-dropdown/DropdownPicker';
import FormField from '../form/FormField';
import { defaultInputStyle, rowStyle } from '../form/FormStyles';
import ErrorPopup from '../popup/ErrorPopup';
import PrimaryPopup from '../popup/PrimaryPopup';
import SuccessPopup from '../popup/SuccessPopup';
import Spinner from '../spinner/Spinner';
import {
  getUser,
  resetUserDetails,
  userFieldsUpdate,
  updateUser,
} from './redux/actions';
import { userStatuses } from './userTypes';

export default function UserDetailsForm() {
  const borderColor = 'search_bg_color';
  const dispatch = useDispatch();
  const {
    error,
    firstNameError,
    lastNameError,
    loading,
    phoneError,
    roleError,
    statusError,
    isUserFormChanged,
    isUserUpdated,
    user,
  } = useSelector((state: RootState) => state.user);
  const [isConfirmationPopupVisible, setConfirmationState] = useState(false);

  const id = isBrowser && localStorage.getItem('userId');

  useEffect(() => {
    dispatch(getUser(id));

    return () => {
      dispatch(resetUserDetails());
      isBrowser && localStorage.removeItem('userId');
    };
  }, []);

  function onInputChange(e) {
    const { name, value } = e.target;

    dispatch(userFieldsUpdate({ [name]: value }));
  }

  const confirmAction = formatMessage('confirmAction');
  const cancel = formatMessage('cancel');
  const updateUserBtn = formatMessage('save');
  const updateUserQuestion = formatMessage('updateUserQuestion');
  const userRole = user && user?.role && formatMessage(user?.role);
  const close = formatMessage('close');
  const userUpdated = formatMessage('userUpdated');
  const errorOccurred = formatMessage('errorOccurred');
  const errorMessage = error && error.code && formatMessage(error?.code);

  const isInvalid =
    firstNameError ||
    lastNameError ||
    phoneError ||
    roleError ||
    statusError ||
    !user?.firstName ||
    !user?.lastName ||
    !user?.phone ||
    !user?.role;

  function openConfirmationPopup(e) {
    e.preventDefault();

    setConfirmationState(true);
  }

  function closeConfirmationPopup() {
    setConfirmationState(false);
  }

  function onSubmit() {
    dispatch(updateUser(user));

    setConfirmationState(false);
  }

  function onClickErrorBack() {
    navigate('/users');
  }

  if (error) {
    return (
      <Div minH="60vh">
        <ErrorPopup
          onSubmit={onClickErrorBack}
          submitText={close}
          title={errorOccurred}
        >
          {errorMessage}
        </ErrorPopup>
      </Div>
    );
  }

  return (
    <form onSubmit={openConfirmationPopup}>
      <Row {...rowStyle} align="baseline">
        {loading && <Spinner />}
        <FormField
          colSize="7"
          error={firstNameError}
          inputStyle={defaultInputStyle}
          label={formatMessage('firstName')}
          inputValue={user && user.firstName}
          inputType="text"
          inputName="firstName"
          onChange={onInputChange}
          required={true}
          tabIndex="1"
        />
      </Row>
      <Row
        {...rowStyle}
        align="baseline"
        border={{ b: '1px solid' }}
        p={{ b: '1rem' }}
        borderColor={borderColor}
      >
        <FormField
          colSize="7"
          error={lastNameError}
          inputStyle={defaultInputStyle}
          label={formatMessage('lastName')}
          inputValue={user && user.lastName}
          inputType="text"
          inputName="lastName"
          onChange={onInputChange}
          required={true}
          tabIndex="2"
        />
      </Row>
      <Row
        {...rowStyle}
        border={{ b: '1px solid' }}
        borderColor={borderColor}
        p={{ b: '1rem' }}
        flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      >
        <DropdownPicker
          inputName="role"
          items={roles}
          label={formatMessage('userRole')}
          labelSize={{ xs: '12', sm: '5' }}
          onChange={onInputChange}
          text={'chooseRole'}
          value={user && user.role}
        />
      </Row>
      <Row
        {...rowStyle}
        border={{ b: '1px solid' }}
        borderColor={borderColor}
        p={{ b: '1rem' }}
        flexWrap={{ xs: 'wrap', sm: 'nowrap' }}
      >
        <DropdownPicker
          inputName="status"
          items={userStatuses}
          label={formatMessage('userStatus')}
          labelSize={{ xs: '12', sm: '5' }}
          onChange={onInputChange}
          text={'chooseStatus'}
          value={user && user.status}
        />
      </Row>
      <Row {...rowStyle} align="baseline" p={{ b: '2rem' }}>
        <FormField
          colSize="7"
          error={phoneError}
          inputStyle={defaultInputStyle}
          label={formatMessage('phone')}
          inputValue={user && user.phone}
          inputType="tel"
          inputName="phone"
          onChange={onInputChange}
          required={true}
          tabIndex="4"
        />
      </Row>
      <PrimaryButton
        icon="Checked"
        size="lg"
        disabled={isInvalid || loading || !isUserFormChanged}
        loading={loading}
      >
        {updateUserBtn}
      </PrimaryButton>
      {isConfirmationPopupVisible && (
        <PrimaryPopup
          title={confirmAction}
          cancelBtnText={cancel}
          onClose={closeConfirmationPopup}
          btnText={updateUserBtn}
          onClick={onSubmit}
        >
          <Row>
            <Col>
              <Text>{updateUserQuestion}</Text>
            </Col>
          </Row>
        </PrimaryPopup>
      )}
      {user && user.id && !isInvalid && isUserUpdated && (
        <SuccessPopup
          onSubmit={() => navigate('/users')}
          submitText={close}
          title={userUpdated}
        >
          <Div>
            <Text>
              {formatMessage('userRole')}: {userRole}{' '}
            </Text>
            {user.status && (
              <Text>
                {formatMessage('userStatus')}:{' '}
                {formatMessage(`${user?.status}`)}{' '}
              </Text>
            )}
            <Text>
              {formatMessage('user')}: {user?.firstName} {user?.lastName}{' '}
            </Text>
          </Div>
        </SuccessPopup>
      )}
      {error && (
        <ErrorPopup
          onSubmit={onClickErrorBack}
          submitText={close}
          title={errorOccurred}
        >
          {errorMessage}
        </ErrorPopup>
      )}
    </form>
  );
}
