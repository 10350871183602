export interface User {
  firstName: string;
  lastName: string;
  phone: string;
  role: string;
  id: string;
  status: string;
}

export interface UserFieldsForUpdate {
  firstName?: string;
  lastName?: string;
  phone?: string;
  role?: string;
  id?: string;
  status?: string;
}

export enum UserStatus {
  DISABLED = 'DISABLED',
  ENABLED = 'ENABLED',
}

export const userStatuses = [UserStatus.ENABLED, UserStatus.DISABLED];
